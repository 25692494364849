import AirEye from "../../../assets/img/AirEyeLogo.png";
import React from "react";

import { READINGS, MultipleReadingsWStatusTbody } from "../../../helper/Readings";
import { useCommunityTypes } from "../../../contexts/CommunityTypesProvider";

const ReportsPDF = React.forwardRef(({ device, readings, yearValue, monthName }, ref) => {
	const { communityTypes } = useCommunityTypes();

	return (
		<div ref={ref} className='page'>
			<div className='header'>
				<div className='logo'>
					<img src={AirEye} alt='AirEye' />
				</div>
				<h1 className='title'>AirEye</h1>
			</div>
			<p className='report-details'>Report for{` ${monthName} ${yearValue}`}</p>
			<div className='content'>
				<table>
					<tbody>
						<tr>
							<td>Name</td>
							<td>{device ? device.name : "Name"}</td>
						</tr>
						<tr>
							<td>Location</td>
							<td>{device ? device.location : "Location"}</td>
						</tr>
						<tr>
							<td>Community Type</td>
							<td>{device ? communityTypes[device?.community_type - 1] : "Type"}</td>
						</tr>
					</tbody>
				</table>
				<table className='reading-table multiple'>
					<thead>
						<tr>
							<th></th>
							{READINGS().map((gas) => (
								<th key={gas.id}>
									{gas.elmnts}
									<sub>{gas.sub}</sub>
								</th>
							))}
							<th>AQI</th>
						</tr>
					</thead>
					<MultipleReadingsWStatusTbody readings={readings} />
				</table>
			</div>
		</div>
	);
});

export default ReportsPDF;
