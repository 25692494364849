import axios from "axios";
import ROUTES from "./RoutesConstants";

const getHeaders = () => ({
	headers: {
		Authorization: "Token " + localStorage.getItem("token").replace(/^"(.+(?="$))"$/, "$1"),
	},
});

// Auth
export const login = (body) => {
	return axios.post(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.AUTH}`, body);
};

// Get Earliest Day Date
export const getEarliestDate = (device_id) => {
	return axios.get(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DATE.BASE}${ROUTES.AQMS.DATE.EARLIEST}${device_id}`
	);
};

// Get Latest Day Date
export const getLatestDate = (device_id) => {
	return axios.get(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DATE.BASE}${ROUTES.AQMS.DATE.LATEST}${device_id}`
	);
};

// Get Latest Live Date
export const getLatestLiveDate = (device_id) => {
	return axios.get(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DATE.BASE}${ROUTES.AQMS.DATE.LATEST_LIVE}${device_id}`
	);
};

// Get Community Types
export const getCommunityTypes = () => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.COMMUNITY}`);
};

// Create Community Type
export const createCommunityType = (body) => {
	return axios.post(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.COMMUNITY}`, body, getHeaders());
};

// Update Community Type
export const updateCommunityType = (id, body) => {
	return axios.put(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.COMMUNITY}${id}`, body, getHeaders());
};

// Delete Community Type
export const deleteCommunityType = (id) => {
	return axios.delete(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.COMMUNITY}${id}`, getHeaders());
};

// Get Devices
export const getDevices = () => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DEVICE.BASE}`);
};

// Get Device
export const getDevice = (id) => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DEVICE.BASE}${id}`);
};

// Create Device
export const createDevice = (body) => {
	return axios.post(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DEVICE.BASE}`, body, getHeaders());
};

// Update Device Detail
export const updateDevice = (id, body) => {
	return axios.put(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DEVICE.BASE}${id}`, body, getHeaders());
};

// Delete Device
export const deleteDevice = (id) => {
	return axios.delete(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DEVICE.BASE}${id}`, getHeaders());
};

// Reset Device
export const resetDevice = (id) => {
	return axios.delete(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DEVICE.BASE}${ROUTES.AQMS.DEVICE.RESET}${id}`,
		getHeaders()
	);
};

// Get Latest Live Readings
export const getLatestLiveReading = (device_id) => {
	return axios.get(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.LIVE.BASE}${ROUTES.AQMS.LIVE.LATEST.BASE}${device_id}`
	);
};

// Get 10 Latest Live Readings from each Device
export const getLatestLiveReadingBy10 = () => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.LIVE.BASE}${ROUTES.AQMS.LIVE.LATEST.TEN}`);
};

// Get all live readings
export const getAllLiveReadings = () => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.LIVE.BASE}${ROUTES.AQMS.LIVE.ALL}`);
};

// Get Day Reading
export const getDayReading = (device_id, date) => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.DAY}${device_id}/${date}`);
};

// Get Historical Data
export const getHistoricalData = (device_id, date_range) => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.HISTORY.BASE}${device_id}/${date_range}`);
};

// Get Historical Data by Month
export const getHistoricalDataByMonth = (device_id, year, month) => {
	return axios.get(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.HISTORY.BASE}${ROUTES.AQMS.HISTORY.MONTH}${device_id}/${year}/${month}`
	);
};

// Get Reports Summary
export const getReportsSummary = (year, month) => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.REPORTS}${year}/${month}`);
};

// Get Prediction
export const getPrediction = (device_id, body) => {
	return axios.post(
		`${ROUTES.AQMS.BASE}${ROUTES.AQMS.PREDICT.BASE}${ROUTES.AQMS.PREDICT.DAYS}${device_id}`,
		body
	);
};

// Get AQI
export const getAQI = (device_id) => {
	return axios.get(`${ROUTES.AQMS.BASE}${ROUTES.AQMS.AQI}${device_id}`);
};
