import "./Header.css";
import AirEyeLogo from "../../../assets/img/AirEye.svg";

import { Link, useParams } from "react-router-dom";
import { BiMenuAltLeft } from "react-icons/bi";

import { FIGURE_TYPES } from "../../../helper/GraphSettings";

const Header = ({ open, setOpen }) => {
	const { device_id, figure } = useParams();

	return (
		<div className='nav'>
			<div className='toggle icon'>
				<BiMenuAltLeft onClick={() => setOpen(!open)} />
			</div>
			<div className='brand'>
				<div className='logo'>
					<img src={AirEyeLogo} alt='AirEye' />
				</div>
				<h1 className='title'>AirEye</h1>
			</div>
			{device_id ? (
				<div className='nav-items'>
					{Object.entries(FIGURE_TYPES).map(([key, value]) => (
						<Link
							key={key}
							to={`/${device_id}/${value}`}
							className={figure && figure === value ? "active" : null}
						>
							{value.charAt(0).toUpperCase() + value.slice(1)}
						</Link>
					))}
				</div>
			) : null}
		</div>
	);
};

export default Header;
