import React from "react";
import { BiX, BiReset } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";

import { ACTIONS } from "../../../contexts/ModalProvider";
import { useSelectedDevice } from "../../../contexts/SelectedDeviceProvider";

import useResetDevice from "../../../hooks/useResetDevice";
import useDeleteDevice from "../../../hooks/useDeleteDevice";

const PromptModal = ({ modal, dispatch }) => {
	const { selectedDevice } = useSelectedDevice();
	const { mutate: resetDevice } = useResetDevice();
	const { mutate: deleteDevice } = useDeleteDevice();

	const ACTION_TYPE = modal.reset ? ACTIONS.RESET : modal.delete ? ACTIONS.DELETE : "";

	const handleSubmit = () => {
		if (modal.reset) {
			resetDevice(selectedDevice.id);
			dispatch({ type: ACTIONS.RESET });
		} else if (modal.delete) {
			deleteDevice(selectedDevice.id);
			dispatch({ type: ACTIONS.DELETE });
		}
	};

	return (
		<div
			className='modal'
			onClick={(e) => e.target === e.currentTarget && dispatch({ type: ACTION_TYPE })}
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<span>{`${ACTION_TYPE} Device`}</span>
					<BiX className='x-icon' onClick={() => dispatch({ type: ACTION_TYPE })} />
				</div>
				<div className='modal-body'>
					<div className='prompt'>
						{modal.reset ? (
							<BiReset className='icon' />
						) : modal.delete ? (
							<FaTrash className='icon' />
						) : null}
						<span>{`${ACTION_TYPE} ${selectedDevice.name}?`}</span>
					</div>
					<div className='modal-footer'>
						<button
							className='btn btn-cancel'
							onClick={() => dispatch({ type: ACTION_TYPE })}
						>
							Cancel
						</button>
						<button className='btn btn-primary' onClick={handleSubmit}>
							{ACTION_TYPE}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PromptModal;
