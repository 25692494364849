import { StatusProvider } from "../../../contexts/StatusProvider";

import DeviceInfo from "../DeviceInfo/DeviceInfo";
import Figures from "../Figures/Figures";

const DashContent = ({ devices }) => {
	return (
		<StatusProvider>
			<div className='content'>
				<DeviceInfo devices={devices} />
				<Figures />
			</div>
		</StatusProvider>
	);
};

export default DashContent;
