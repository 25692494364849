export default {
	AQMS: {
		// BASE: `http://127.0.0.1:8000/api/`,
		BASE: `https://aireye.localtonet.com/api/`,
		AUTH: `auth/`,
		DATE: {
			BASE: `date/`,
			EARLIEST: `earliest/`,
			LATEST: `latest/`,
			LATEST_LIVE: `latest/live/`,
		},
		COMMUNITY: "community/",
		DEVICE: {
			BASE: `device/`,
			EXPANDED: `expanded/`,
			RESET: `reset/`,
		},
		LIVE: {
			BASE: `live/`,
			LATEST: {
				BASE: `latest/`,
				TEN: `ten/`,
			},
			ALL: `all/`,
			DAY: `day/`,
		},
		DAY: `day/`,
		HISTORY: {
			BASE: `history/`,
			MONTH: `month/`,
		},
		PREDICT: {
			BASE: `predict/`,
			DAY: `day/`,
			DAYS: `days/`,
		},
		REPORTS: `reports/summary/`,
		AQI: `aqi/`,
		ADD: `add/`,
	},
};
