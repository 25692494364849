import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { subDays, addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useDateRange } from "../../../contexts/DateRangeProvider";
import useLatestDateQuery from "../../../hooks/useLatestDateQuery";

const DateRangePrediction = () => {
	const { device_id } = useParams();
	const { data: latestDate } = useLatestDateQuery(device_id);
	const { setDateRangePrediction } = useDateRange();

	// Date state
	const [maxDate, setMaxDate] = useState(new Date());
	const [range, setRange] = useState();

	// Toggle datepicker open close
	const [open, setOpen] = useState(false);

	// Get the target element to toggle
	const refOne = useRef(null);

	useEffect(() => {
		// Event listeners
		document.addEventListener("keydown", hideOnEscape, true);
		document.addEventListener("click", hideOnClickOutside, true);
	}, []);

	useEffect(() => {
		if (latestDate) {
			setRange([
				{
					startDate: addDays(new Date(latestDate?.data?.date), 1),
					endDate: addDays(new Date(latestDate?.data?.date), 7),
					key: "selection",
				},
			]);
			setDateRangePrediction({
				start: format(addDays(new Date(latestDate?.data?.date), 1), "yyyy-MM-dd"),
				end: format(addDays(new Date(latestDate?.data?.date), 7), "yyyy-MM-dd"),
			});
			setMaxDate(addDays(new Date(latestDate?.data?.date), 7));
		} else {
			setRange([
				{
					startDate: new Date(),
					endDate: new Date(),
					key: "selection",
				},
			]);
		}
	}, [latestDate, setDateRangePrediction]);

	// Hide dropdown on ESC press
	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	};

	// Hide on outside click
	const hideOnClickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	};

	const handleSelect = (item) => {
		setRange([item.selection]);
		setDateRangePrediction({
			start: format(item.selection.startDate, "yyyy-MM-dd"),
			end: format(item.selection.endDate, "yyyy-MM-dd"),
		});
	};

	return (
		<div className='calendarWrap'>
			{range ? (
				<>
					<input
						className='inputBox'
						value={`${format(range[0].startDate, "MM/dd/yyyy")} - ${format(
							range[0].endDate,
							"MM/dd/yyyy"
						)}`}
						onClick={() => setOpen((open) => !open)}
						readOnly
					/>

					<div ref={refOne}>
						{open && (
							<DateRange
								minDate={
									latestDate
										? addDays(new Date(latestDate?.data?.date), 1)
										: new Date()
								}
								maxDate={maxDate}
								onChange={handleSelect}
								editableDateInputs={true}
								moveRangeOnFirstSelection={false}
								ranges={range}
								months={1}
								rangeColors={["rgb(90, 115, 172)"]}
								direction='horizontal'
								className='calendarElement'
							/>
						)}
					</div>
				</>
			) : null}
		</div>
	);
};

export default DateRangePrediction;
