import useHistoricalDataQuery from "../../../hooks/useHistoricalDataQuery";

import Loading from "../../Loading/Loading";
import Error from "../../Error/Error";

import HistoricalTable from "./HistoricalTable";
import HistoricalGraph from "./HistoricalGraph";

const Historical = ({ FORMAT, selectedFormat }) => {
	const { data, isLoading, isError } = useHistoricalDataQuery();

	return (
		<>
			{isLoading ? (
				<Loading />
			) : isError ? (
				<Error />
			) : (
				<>
					{selectedFormat === FORMAT.TABLE ? (
						<HistoricalTable data={data} />
					) : selectedFormat === FORMAT.GRAPH ? (
						<HistoricalGraph data={data} />
					) : null}
				</>
			)}
		</>
	);
};

export default Historical;
