import { READINGS, MultipleReadingsTbody } from "../../../helper/Readings";

const HistoricalTable = ({ data }) => {
	return (
		<table className='reading-table multiple'>
			<thead>
				<tr>
					<th></th>
					{READINGS().map((gas) => (
						<th key={gas.id}>
							{gas.elmnts}
							<sub>{gas.sub}</sub>
						</th>
					))}
				</tr>
			</thead>
			<MultipleReadingsTbody readings={data?.data} />
		</table>
	);
};

export default HistoricalTable;
