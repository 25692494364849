import { useQueryClient } from "@tanstack/react-query";
import { updateDevice } from "../services/ApiServices";

export default function useEditDevice() {
    const queryClient = useQueryClient();

    const editDevice = async (id, body) => {
        try {
            const res = await updateDevice(id, body);
            queryClient.invalidateQueries("devices");
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    };
    return { editDevice };
}
