import { useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import useDevicesQuery from "../../hooks/useDevicesQuery";

import { FIGURE_TYPES } from "../../helper/GraphSettings";

const DashboardRoute = ({ children }) => {
	const navigate = useNavigate();
	const { data: devices } = useDevicesQuery();
	const { device_id, figure } = useParams();

	useEffect(() => {
		if (devices) {
			const devices_id = devices?.data.map((device) => device.id);

			// Check if device_id and figure route is valid
			if ((device_id && !devices_id?.includes(Number(device_id))) || (figure && !Object.values(FIGURE_TYPES).includes(figure))) {
				navigate("/404");
			}

			// Check if device_id route is valid but figure is not specified
			if (device_id && !figure) {
				navigate(`/${device_id}/live`);
			}
		}
	}, [devices, device_id]);

	return (
		<>
			{children}
			<Outlet context={{ devices, device_id }} />
		</>
	);
};

export default DashboardRoute;
