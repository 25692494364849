import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAQI } from "../services/ApiServices";
import { aqi_category } from "../helper/AQILevels";

export default function useAQI() {
	const { device_id } = useParams();

	const { data } = useQuery({
		queryKey: ["AQI", device_id],
		queryFn: () => {
			return getAQI(device_id);
		},
		enabled: !!device_id,
	});

	if (data) return aqi_category(data?.data);
}
