import "./Toggle.css";

import { BiGridAlt, BiChart } from "react-icons/bi";

const Toggle = ({ format, selectedFormat, setSelectedFormat }) => {
	const handleToggle = (format) => {
		setSelectedFormat(format);
	};
	return (
		<div className='toggle'>
			{Object.entries(format).map(([key, value]) => (
				<button
					className={`item ${selectedFormat === value ? "active" : ""}`}
					onClick={() => handleToggle(value)}
					key={key}
				>
					{value === format.TABLE ? (
						<BiGridAlt />
					) : value === format.GRAPH ? (
						<BiChart />
					) : null}
				</button>
			))}
		</div>
	);
};

export default Toggle;
