import React, { useState, useContext } from "react";

const DateRangeContext = React.createContext();

export function useDateRange() {
	return useContext(DateRangeContext);
}

export function DateRangeProvider({ children }) {
	const [dateRangeHistory, setDateRangeHistory] = useState("");
	const [dateRangePrediction, setDateRangePrediction] = useState({ start: "", end: "" });
	return (
		<DateRangeContext.Provider value={{ dateRangeHistory, setDateRangeHistory, dateRangePrediction, setDateRangePrediction }}>
			{children}
		</DateRangeContext.Provider>
	);
}
