import React, { useState, useContext } from "react";

const DateContext = React.createContext();

export function useDate() {
    return useContext(DateContext);
}

export function DateProvider({ children }) {
    const [date, setDate] = useState();
    const [earliest, setEarliest] = useState();

    return <DateContext.Provider value={{ date, setDate, earliest, setEarliest }}>{children}</DateContext.Provider>;
}
