import { useQuery } from "@tanstack/react-query";
import { getLatestLiveReading } from "../services/ApiServices";

export default function useLiveDataQuery(id) {
    const fetchLiveData = () => {
        return getLatestLiveReading(id);
    };

    return useQuery({
        queryKey: ["liveData", id],
        queryFn: fetchLiveData,
        refetchInterval: 5000,
        enabled: !!id,
    });
}
