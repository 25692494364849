import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import format from "date-fns/format";

import { getPrediction } from "../../../services/ApiServices";
import { useDateRange } from "../../../contexts/DateRangeProvider";

import Loading from "../../Loading/Loading";
import Error from "../../Error/Error";

import PredictionTable from "./PredictionTable";
import PredictionGraph from "./PredictionGraph";

const Prediction = ({ FORMAT, selectedFormat }) => {
	const { device_id } = useParams();
	const { dateRangePrediction } = useDateRange();
	const [prediction, setPrediction] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	const getDatesInRange = (startDate, endDate) => {
		const date = new Date(startDate.getTime());
		const dates = [];

		while (date <= endDate) {
			let temp = format(new Date(date), "yyyy-MM-dd");
			temp = temp.split("-");
			const formatted_date = {
				year: Number(temp[0]),
				month: Number(temp[1]),
				day: Number(temp[2]),
			};
			dates.push(formatted_date);
			date.setDate(date.getDate() + 1);
		}

		return dates;
	};

	const fetchPredictions = async (id, body) => {
		setIsError(false);
		setIsLoading(true);
		try {
			const res = await getPrediction(id, body);
			setPrediction(res?.data);
		} catch (err) {
			setIsError(true);
			console.log(`Error: ${err.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (dateRangePrediction.start && dateRangePrediction.end) {
			const dates = getDatesInRange(
				new Date(dateRangePrediction.start),
				new Date(dateRangePrediction.end)
			);
			fetchPredictions(device_id, { dates: dates });
		}
	}, [dateRangePrediction]);

	return (
		<>
			{isLoading ? (
				<Loading />
			) : isError ? (
				<Error />
			) : (
				<>
					{selectedFormat === FORMAT.TABLE ? (
						<PredictionTable prediction={prediction} />
					) : selectedFormat === FORMAT.GRAPH ? (
						<PredictionGraph prediction={prediction} />
					) : null}
				</>
			)}
		</>
	);
};

export default Prediction;
