import { READINGS } from "../../../helper/Readings";

const ReadingControls = ({
	search,
	setSearch,
	communityTypes,
	onComTypeFilterChange,
	onSensorFilterChange,
}) => {
	return (
		<div className='reading-controls'>
			<input
				type='text'
				placeholder='Search...'
				value={search}
				className='device-search'
				onChange={(e) => setSearch(e.target.value)}
			/>
			<div className='filter-title'>Filter by Community Type</div>
			<div className='filter'>
				{communityTypes.map((comType, idx) => (
					<label key={idx}>
						<input type='checkbox' onChange={onComTypeFilterChange} value={comType} />
						<span>{comType}</span>
					</label>
				))}
			</div>
			<div className='filter-title'>Filter Gas Readings</div>
			<div className='filter'>
				{READINGS().map((reading) => (
					<label key={reading.id}>
						<input
							type='checkbox'
							onChange={onSensorFilterChange}
							value={reading.sensor}
						/>
						{/* <span>{reading.sensor}</span> */}
						<span>
							<span>{reading.elmnts}</span>
							<sub>{reading.sub}</sub>
						</span>
					</label>
				))}
			</div>
		</div>
	);
};

export default ReadingControls;
