import "./Error.css";

import { BsClipboardData } from "react-icons/bs";

const Error = () => {
	return (
		<div className='error'>
			<BsClipboardData className="icon" />
			<div className="prompt">No readings found on this day.</div>
		</div>
	);
};

export default Error;
