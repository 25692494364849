import "./AQI.css";

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { BiBulb } from "react-icons/bi";
import format from "date-fns/format";

import useAQI from "../../../hooks/useAQI";
import { AQI_LEVELS } from "../../../helper/AQILevels";

const AQI = () => {
	const { device_id } = useParams();
	const aqi = useAQI();

	const date = useMemo(() => (aqi ? format(new Date(aqi?.date), "MMM dd, yyyy") : null), [aqi]);

	return (
		<div
			className={`aqi-container ${AQI_LEVELS[aqi?.idx]?.class} ${
				device_id && aqi ? "open" : ""
			}`}
		>
			<div className='aqi'>
				<BiBulb className='icon' />
				<div>
					<div className='name'>{AQI_LEVELS[aqi?.idx]?.category}</div>
					<div className='date'>Air Quality Status as of {date}</div>
				</div>
			</div>
			<div className='description'>{AQI_LEVELS[aqi?.idx]?.description}</div>
		</div>
	);
};

export default AQI;
