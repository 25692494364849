import { SiArduino } from "react-icons/si";
import { BsPencilSquare } from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";

import { useCommunityTypes } from "../../../contexts/CommunityTypesProvider";
import { useSelectedDevice } from "../../../contexts/SelectedDeviceProvider";
import { ACTIONS, useModal } from "../../../contexts/ModalProvider";

const Device = ({ device }) => {
	const { communityTypes } = useCommunityTypes();
	const { dispatch } = useModal();
	const { setSelectedDevice } = useSelectedDevice();

	const handleEdit = () => {
		setSelectedDevice(device);
		dispatch({ type: ACTIONS.EDIT });
	};

	const handleViewCode = () => {
		setSelectedDevice(device);
		dispatch({ type: ACTIONS.VIEW });
	};

	const handleReset = () => {
		setSelectedDevice(device);
		dispatch({ type: ACTIONS.RESET });
	};

	const handleDelete = () => {
		setSelectedDevice(device);
		dispatch({ type: ACTIONS.DELETE });
	};

	return (
		<tr>
			<td>{device.name}</td>
			<td>{device.location}</td>
			<td>{communityTypes[device?.community_type - 1]}</td>
			<td>
				<div className='actions-column'>
					<button className='action-btn' onClick={handleEdit}>
						<BsPencilSquare className='action-icon' />
					</button>
					<button className='action-btn' onClick={handleViewCode}>
						<SiArduino className='action-icon' />
					</button>
					<button className='action-btn' onClick={handleReset}>
						<BiReset className='action-icon' />
					</button>
					<button className='action-btn' onClick={handleDelete}>
						<FaTrash className='action-icon' />
					</button>
				</div>
			</td>
		</tr>
	);
};

export default Device;
