import { useQuery } from "@tanstack/react-query";
import { getHistoricalDataByMonth, getReportsSummary } from "../services/ApiServices";

export function useReportsQuery(id, year, month) {
	return useQuery({
		queryKey: ["reports", id, year, month],
		queryFn: () => getHistoricalDataByMonth(id, year, month),
		enabled: !!id && !!year && !!month,
	});
}

export function useReportsSummaryQuery(year, month) {
	return useQuery({
		queryKey: ["reports-summary", year, month],
		queryFn: () => getReportsSummary(year, month),
		enabled: !!year && !!month,
	});
}
