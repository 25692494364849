import React, { useContext, useMemo } from "react";

import { useParams } from "react-router-dom";
import useLatestLiveDateQuery from "../hooks/useLatestLiveDateQuery";

const StatusContext = React.createContext();

export function useStatus() {
	return useContext(StatusContext);
}

export function StatusProvider({ children }) {
	const { device_id } = useParams();
	const { data: latestLiveDate } = useLatestLiveDateQuery(device_id);

	const status = useMemo(
		() =>
			Math.abs(new Date() - new Date(latestLiveDate?.data?.date)) / 60000 <= 1
				? "Online"
				: "Offline",
		[latestLiveDate]
	);

	return <StatusContext.Provider value={{ status }}>{children}</StatusContext.Provider>;
}
