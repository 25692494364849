import { useQuery } from "@tanstack/react-query";
import { getLatestLiveDate } from "../services/ApiServices";

export default function useLatestLiveDateQuery(id) {
	return useQuery({
		queryKey: ["latestLiveDate", String(id)],
		queryFn: () => {
			return getLatestLiveDate(id);
		},
		enabled: !!id,
		refetchInterval: 60000,
	});
}
