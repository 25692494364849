import useDayDataQuery from "../../../hooks/useDayDataQuery";

import Loading from "../../Loading/Loading";
import Error from "../../Error/Error";

import DayTable from "./DayTable";
import DayGraph from "./DayGraph";

const Day = ({ FORMAT, selectedFormat }) => {
	const { data, isLoading, isError } = useDayDataQuery();

	return (
		<>
			{isLoading ? (
				<Loading />
			) : isError ? (
				<Error />
			) : (
				<>
					{selectedFormat === FORMAT.TABLE ? (
						<DayTable data={data} />
					) : selectedFormat === FORMAT.GRAPH ? (
						<DayGraph data={data} />
					) : null}
				</>
			)}
		</>
	);
};

export default Day;
