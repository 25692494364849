import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { subDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useDateRange } from "../../../contexts/DateRangeProvider";
import useEarliestDateQuery from "../../../hooks/useEarliestDateQuery";
import useLatestDateQuery from "../../../hooks/useLatestDateQuery";

const DateRangeHistory = () => {
	const { device_id } = useParams();
	const { data: earliestDate, isError: earliestDateError } = useEarliestDateQuery(device_id);
	const { data: latestDate, isError: latestDateError } = useLatestDateQuery(device_id);
	const { setDateRangeHistory } = useDateRange();

	// Date state
	const [range, setRange] = useState();

	// Toggle datepicker open close
	const [open, setOpen] = useState(false);

	// Get the target element to toggle
	const refOne = useRef(null);

	useEffect(() => {
		// Event listeners
		document.addEventListener("keydown", hideOnEscape, true);
		document.addEventListener("click", hideOnClickOutside, true);
	}, []);

	useEffect(() => {
		if (!earliestDateError && !latestDateError) {
			if (new Date(latestDate?.data?.date) < subDays(new Date(), 1)) {
				setRange([
					{
						startDate: subDays(new Date(latestDate?.data?.date), 6),
						endDate: new Date(latestDate?.data?.date),
						key: "selection",
					},
				]);
				setDateRangeHistory(
					`${format(subDays(new Date(latestDate?.data?.date), 6), "yyyy-MM-dd")}~${format(
						new Date(latestDate?.data?.date),
						"yyyy-MM-dd"
					)}`
				);
			} else {
				setRange([
					{
						startDate: subDays(new Date(), 6),
						endDate: subDays(new Date(), 1),
						key: "selection",
					},
				]);
				setDateRangeHistory(
					`${format(subDays(new Date(), 6), "yyyy-MM-dd")}~${format(
						subDays(new Date(), 1),
						"yyyy-MM-dd"
					)}`
				);
			}
		} else {
			setRange([
				{
					startDate: new Date(),
					endDate: new Date(),
					key: "selection",
				},
			]);
		}
	}, [earliestDate, latestDate, setDateRangeHistory]);

	// Hide dropdown on ESC press
	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	};

	// Hide on outside click
	const hideOnClickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	};

	const handleSelect = (item) => {
		setRange([item.selection]);
		setDateRangeHistory(
			`${format(item.selection.startDate, "yyyy-MM-dd")}~${format(
				item.selection.endDate,
				"yyyy-MM-dd"
			)}`
		);
	};

	return (
		<div className='calendarWrap'>
			{range ? (
				<>
					<input
						className='inputBox'
						value={`${format(range[0].startDate, "MM/dd/yyyy")} - ${format(
							range[0].endDate,
							"MM/dd/yyyy"
						)}`}
						onClick={() => setOpen((open) => !open)}
						readOnly
					/>

					<div ref={refOne}>
						{open && (
							<DateRange
								minDate={
									earliestDate ? new Date(earliestDate?.data?.date) : new Date()
								}
								maxDate={
									latestDate
										? new Date(latestDate?.data?.date)
										: subDays(new Date(), 1)
								}
								onChange={handleSelect}
								editableDateInputs={true}
								moveRangeOnFirstSelection={false}
								ranges={range}
								months={1}
								rangeColors={["rgb(90, 115, 172)"]}
								direction='horizontal'
								className='calendarElement'
							/>
						)}
					</div>
				</>
			) : null}
		</div>
	);
};

export default DateRangeHistory;
