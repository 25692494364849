import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

import { LINE_DATA, LINE_OPTIONS } from "../../../helper/GraphSettings";

const PredictionGraph = ({ prediction }) => {
	return (
		<div className='line'>
			<div className='line-graph'>
				<Line data={LINE_DATA(prediction)} options={LINE_OPTIONS} />
			</div>
		</div>
	);
};

export default PredictionGraph;
