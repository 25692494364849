import "./DeviceInfo.css";

import { useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BiLeftArrowAlt } from "react-icons/bi";

import { useCommunityTypes } from "../../../contexts/CommunityTypesProvider";
import { useStatus } from "../../../contexts/StatusProvider";

const DeviceInfo = ({ devices }) => {
	const { device_id } = useParams();
	const { communityTypes } = useCommunityTypes();
	const { status } = useStatus();
	const navigate = useNavigate();

	const device = useMemo(
		() => devices?.find((device) => device.id === Number(device_id)),
		[devices, device_id]
	);

	const handleNavBack = () => {
		navigate("/");
	};

	return (
		<div className='device-info'>
			<div className='device-name'>
				<BiLeftArrowAlt className='icon' onClick={handleNavBack} />
				{device?.name}
			</div>
			<div className='device-loc'>{device?.location}</div>
			<div className='comm-type'>{communityTypes[device?.community_type - 1]}</div>
			<div className={status ? `status ${status}` : "status"}>
				<div></div>
				{status ? status : "Loading"}
			</div>
		</div>
	);
};

export default DeviceInfo;
