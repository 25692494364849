import { useLocation, Navigate, Outlet } from "react-router-dom";

import useLocalStorage from "../../hooks/useLocalStorage";

const PrivateRoute = ({ children }) => {
	const location = useLocation();
	const { storedValue: token } = useLocalStorage("token", null);

	if (!token) {
		return <Navigate to='/login' />;
	}
	if (location.pathname === "/admin") {
		return <Navigate to='/admin/devices' />;
	}

	return (
		<>
			{children}
			<Outlet />
		</>
	);
};

export default PrivateRoute;
