import "./PlacesAutocomplete.css";

import usePlacesAutocomplete from "use-places-autocomplete";
import Autosuggest from "react-autosuggest";

import usePlaces from "../../../hooks/usePlaces";

const PlacesAutocomplete = () => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const {
        inputProps,
        renderSearchInputComponent,
        getSuggestionValue,
        renderSuggestion,
        onSuggestionsFetchRequested,
        onSuggestionsClearRequested,
        onSuggestionSelected,
    } = usePlaces(ready, value, setValue, status, data, clearSuggestions);

    return (
        <div className='card search'>
            <Autosuggest
                inputProps={inputProps(true)}
                renderInputComponent={renderSearchInputComponent}
                suggestions={data}
                getSuggestionValue={getSuggestionValue}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                renderSuggestion={renderSuggestion}
            />
        </div>
    );
};

export default PlacesAutocomplete;
