import { BiLogOut } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.clear();
		navigate("/");
	};

	return (
		<div className='header'>
			<h1 className='title'>Admin</h1>
			<div className='nav-items'>
				<Link
					to={"/admin/devices"}
					className={location.pathname === "/admin/devices" ? "active" : ""}
				>
					Devices
				</Link>
				<Link
					to={"/admin/reports"}
					className={location.pathname === "/admin/reports" ? "active" : ""}
				>
					Reports
				</Link>
			</div>
			<button className='btn btn-secondary no-text' onClick={handleLogout}>
				<BiLogOut className='icon' />
			</button>
		</div>
	);
};

export default Header;
