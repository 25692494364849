import { useQuery } from "@tanstack/react-query";
import { CSVLink } from "react-csv";
import { BiX } from "react-icons/bi";

import { getAllLiveReadings } from "../../../services/ApiServices";
import { ACTIONS } from "../../../contexts/ModalProvider";

const CSVModal = ({ dispatch }) => {
	const { data, isLoading } = useQuery({
		queryKey: ["allLiveReadings"],
		queryFn: getAllLiveReadings,
	});

	return (
		<div
			className='modal'
			onClick={(e) => e.target === e.currentTarget && dispatch({ type: ACTIONS.CSV })}
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<span>Arduino Code</span>
					<BiX className='x-icon' onClick={() => dispatch({ type: ACTIONS.CSV })} />
				</div>
				<div className='modal-body csv'>
					<div className='downloading-container'>
						<div className='downloading'>
							<span className={`custom-arrow ${!isLoading && `stop`}`}></span>
						</div>
						<div className='bar'></div>
					</div>
					<div className='modal-footer'>
						{!isLoading ? (
							<CSVLink data={data?.data} filename={"all-live-readings.csv"}>
								<span className='btn btn-primary'>Download</span>
							</CSVLink>
						) : (
							"Loading..."
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CSVModal;
