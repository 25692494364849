import { useQuery } from "@tanstack/react-query";
import { getLatestDate } from "../services/ApiServices";

export default function useLatestDateQuery(id) {
	const fetchLatestDate = () => {
		return getLatestDate(id);
	};

	return useQuery({
		queryKey: ["latestDate", String(id)],
		queryFn: fetchLatestDate,
		enabled: !!id,
	});
}
