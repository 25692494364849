import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

import { DOUGHNUT_DATA, DOUGHNUT_OPTIONS } from "../../../helper/GraphSettings";

const DayGraph = ({ data }) => {
	return (
		<div className='doughnut'>
			<div className='doughnut-graph'>
				<Doughnut data={DOUGHNUT_DATA(data?.data)} options={DOUGHNUT_OPTIONS} />
			</div>
		</div>
	);
};

export default DayGraph;
