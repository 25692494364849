import React, { useContext, useReducer } from "react";

const ModalContext = React.createContext();

export const ACTIONS = {
	ADD: "Add",
	EDIT: "Edit",
	VIEW: "View Code",
	RESET: "Reset",
	DELETE: "Delete",
	CSV: "CSV",
};

function reducer(modal, action) {
	switch (action.type) {
		case ACTIONS.ADD:
			return { ...modal, add: !modal.add };
		case ACTIONS.EDIT:
			return { ...modal, edit: !modal.edit };
		case ACTIONS.VIEW:
			return { ...modal, view: !modal.view };
		case ACTIONS.RESET:
			return { ...modal, reset: !modal.reset };
		case ACTIONS.DELETE:
			return { ...modal, delete: !modal.delete };
		case ACTIONS.CSV:
			return { ...modal, csv: !modal.csv };
		default:
			return modal;
	}
}

export function useModal() {
	return useContext(ModalContext);
}

export function ModalProvider({ children }) {
	const [modal, dispatch] = useReducer(reducer, {
		add: false,
		edit: false,
		view: false,
		reset: false,
		delete: false,
		csv: false,
	});

	return <ModalContext.Provider value={{ modal, dispatch }}>{children}</ModalContext.Provider>;
}
