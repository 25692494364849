import "./Admin.css";

import { useLocation } from "react-router-dom";

import { CommunityTypesProvider } from "../../contexts/CommunityTypesProvider";
import { ModalProvider } from "../../contexts/ModalProvider";
import { SelectedDeviceProvider } from "../../contexts/SelectedDeviceProvider";

import useDevicesQuery from "../../hooks/useDevicesQuery";
import Header from "./Header";
import Devices from "./Devices/Devices";
import Reports from "./Reports/Reports";
import Modal from "./Modals/Modal";

const Admin = () => {
	const location = useLocation();
	const { data: devices } = useDevicesQuery();

	return (
		<CommunityTypesProvider>
			<ModalProvider>
				<SelectedDeviceProvider>
					<main className='admin'>
						<Header />
						{location.pathname === "/admin/devices" ? (
							<Devices devices={devices} />
						) : location.pathname === "/admin/reports" ? (
							<Reports devices={devices} />
						) : null}
					</main>
					<Modal />
				</SelectedDeviceProvider>
			</ModalProvider>
		</CommunityTypesProvider>
	);
};

export default Admin;
