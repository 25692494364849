import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getHistoricalData } from "../services/ApiServices";
import { useDateRange } from "../contexts/DateRangeProvider";

export default function useHistoricalDataQuery() {
	const { device_id } = useParams();
	const { dateRangeHistory } = useDateRange();

	const fetchHistoricalData = () => {
		return getHistoricalData(device_id, dateRangeHistory);
	};

	return useQuery({
		queryKey: ["historicalData", device_id, dateRangeHistory],
		queryFn: fetchHistoricalData,
		enabled: !!device_id && !!dateRangeHistory,
	});
}
