import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";

import { MapRefProvider } from "./contexts/MapRefProvider";

import Dashboard from "./components/Dashboard/Dashboard";
import DashboardRoute from "./components/Dashboard/DashboardRoute";
import PrivateRoute from "./components/Admin/PrivateRoute";
import Admin from "./components/Admin/Admin";
import Login from "./components/Login/Login";
import PageNotFound from "./components/PageNotFound/PageNotFound";

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			// retry: false,
		},
	},
	logger: {
		log: () => {},
		warn: () => {},
		error: () => {},
	},
});

function App() {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries: ["places"],
	});

	return (
		<>
			{isLoaded ? (
				<QueryClientProvider client={queryClient}>
					<MapRefProvider>
						<Router>
							<Routes>
								<Route exact path='/' element={<DashboardRoute />}>
									<Route exact path='/' element={<Dashboard />}>
										<Route path='/:device_id' element={<Dashboard />}>
											<Route
												path='/:device_id/:figure'
												element={<Dashboard />}
											/>
										</Route>
									</Route>
								</Route>
								<Route path='/login' element={<Login />} />
								<Route path='/admin' element={<PrivateRoute />}>
									<Route path='/admin/devices' element={<Admin />} />
									<Route path='/admin/reports' element={<Admin />} />
								</Route>
								<Route path='/404' element={<PageNotFound />} status={404} />
							</Routes>
						</Router>
						{/* <ReactQueryDevtools initialIsOpen={false} /> */}
					</MapRefProvider>
				</QueryClientProvider>
			) : null}
		</>
	);
}

export default App;
