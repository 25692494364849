import { useQuery } from "@tanstack/react-query";
import { getDevices } from "../services/ApiServices";

export default function useDevicesQuery() {
    const fetchDevices = () => {
        return getDevices();
    };

    return useQuery({
        queryKey: ["devices"],
        queryFn: fetchDevices,
    });
}
