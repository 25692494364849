import { READINGS, ReadingStatusCell } from "../../../helper/Readings";

const DayTable = ({ data }) => {
	return (
		<table className='reading-table'>
			<thead>
				<tr>
					<th>Gas</th>
					<th>Status</th>
					<th>Standard</th>
					<th>Reading</th>
				</tr>
			</thead>
			<tbody>
				{READINGS(data).map((reading) => (
					<tr key={reading.id}>
						<td>{reading.gas}</td>
						<ReadingStatusCell reading={reading} />
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default DayTable;
