import "./Reports.css";

import React, { useState, useRef, useMemo } from "react";
import { BiData } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { jsPDF } from "jspdf";

import useEarliestDateQuery from "../../../hooks/useEarliestDateQuery";
import useLatestDateQuery from "../../../hooks/useLatestDateQuery";
import { useReportsQuery, useReportsSummaryQuery } from "../../../hooks/useReportsQuery";
import { ACTIONS, useModal } from "../../../contexts/ModalProvider";

import ReportsPDF from "./ReportsPDF";
import ReportsSummary from "./ReportsSummary";

const REPORT_TYPE = [
	{ value: "MonthlyAve", label: "Monthly Summary of Readings" },
	{ value: "MonthlyDayReading", label: "Daily Readings of a Month" },
];

const Reports = ({ devices }) => {
	const { dispatch } = useModal();
	const [reportType, setReportType] = useState(REPORT_TYPE[0].value);
	const [MAYearValue, setMAYearValue] = useState("");
	const [MAMonthValue, setMAMonthValue] = useState("");

	const [device, setDevice] = useState(null);
	const [deviceID, setDeviceID] = useState("");
	const [yearValue, setYearValue] = useState("");
	const [monthValue, setMonthValue] = useState("");
	const pdfRef = useRef();

	const { data: earliestDate, isError: earliestDateError } = useEarliestDateQuery(deviceID);
	const { data: latestDate, isError: latestDateError } = useLatestDateQuery(deviceID);

	const { data: monthlyReport } = useReportsQuery(deviceID, yearValue, monthValue);
	const { data: reportsSummary } = useReportsSummaryQuery(MAYearValue, MAMonthValue);

	const years = useMemo(() => {
		if (!earliestDateError && !latestDateError) {
			const yearsArr = [];
			const startYear = earliestDate?.data?.date.split("-")[0];
			const endYear = latestDate?.data?.date.split("-")[0];

			for (let i = Number(startYear); i <= Number(endYear); i++) {
				yearsArr.push(i);
			}
			return yearsArr;
		} else {
			return [];
		}
	}, [earliestDate, latestDate]);

	const months = useMemo(() => {
		if (!earliestDateError && !latestDateError && yearValue) {
			const startYear = earliestDate?.data?.date.split("-")[0];
			const endYear = latestDate?.data?.date.split("-")[0];

			if (Number(yearValue) > Number(startYear) && Number(yearValue) < Number(endYear)) {
				return MONTHS;
			} else if (Number(yearValue) === Number(endYear)) {
				const monthsArr = [];
				const endMonth = latestDate?.data?.date.split("-")[1];
				for (let i = 1; i <= Number(endMonth); i++) {
					i < 10 ? monthsArr.push(`0${i}`) : monthsArr.push(`${i}`);
				}
				return MONTHS.filter((month) => monthsArr.includes(month.value));
			} else if (Number(yearValue) === Number(startYear)) {
				const monthsArr = [];
				const startMonth = earliestDate?.data?.date.split("-")[1];
				for (let i = Number(startMonth); i <= 12; i++) {
					i < 10 ? monthsArr.push(`0${i}`) : monthsArr.push(`${i}`);
				}
				return MONTHS.filter((month) => monthsArr.includes(month.value));
			}
		} else {
			return [];
		}
	}, [yearValue, earliestDate, latestDate]);

	const monthName = useMemo(() => {
		if (monthValue) {
			return MONTHS.find((month) => month.value === monthValue).month;
		} else {
			return "";
		}
	}, [monthValue]);

	const handleDeviceChange = (e) => {
		setYearValue("");
		setMonthValue("");
		setDeviceID(e.target.value);
		setDevice(devices?.data?.find((device) => device.id === Number(e.target.value)));
	};

	const handleYearChange = (e) => {
		setMonthValue("");
		setYearValue(e.target.value);
	};

	const handleMonthChange = (e) => {
		setMonthValue(e.target.value);
	};

	const handleDownload = () => {
		const content = pdfRef.current;

		const doc = new jsPDF("p", "pt", "letter");

		doc.html(content, {
			callback: function (doc) {
				doc.save(
					reportType === REPORT_TYPE[0].value
						? `Report-Summary-${MAYearValue}-${MAMonthValue}`
						: `Reports-${device.name}-${yearValue}-${monthValue}`
				);
			},
			html2canvas: { scale: 0.75 },
		});
	};

	return (
		<div className='content reports'>
			<div className='card report-inputs'>
				<h1 className='title'>Reports</h1>
				<div className='report-type'>
					<span>Report Types</span>
					{REPORT_TYPE.map((report) => (
						<label key={report.value}>
							<input
								type='radio'
								name='report'
								value={report.value}
								checked={reportType === report.value}
								onChange={(e) => {
									setReportType(e.target.value);
								}}
							/>
							<span>{report.label}</span>
						</label>
					))}
				</div>
				<div className='options'>
					{reportType === REPORT_TYPE[0].value ? (
						<>
							<span>Options</span>
							<select
								value={MAYearValue}
								onChange={(e) => {
									setMAYearValue(e.target.value);
								}}
							>
								<option value='' disabled>
									Year
								</option>
								<option value='2023'>2023</option>
								<option value='2022'>2022</option>
							</select>
							<select
								value={MAMonthValue}
								onChange={(e) => {
									setMAMonthValue(e.target.value);
								}}
							>
								<option value='' disabled>
									Month
								</option>
								{MONTHS?.map((month) => (
									<option key={month.value} value={month.value}>
										{month.month}
									</option>
								))}
							</select>
						</>
					) : reportType === REPORT_TYPE[1].value ? (
						<>
							<span>Options</span>
							<select value={deviceID} onChange={handleDeviceChange}>
								<option value='' disabled>
									Select Device
								</option>
								{devices?.data?.map((device) => (
									<option key={device.id} value={device.id}>
										{device.name}
									</option>
								))}
							</select>
							<select
								disabled={years?.length === 0 ? true : false}
								value={yearValue}
								onChange={handleYearChange}
							>
								<option value='' disabled>
									Year
								</option>
								{years?.map((year, idx) => (
									<option key={idx} value={year}>
										{year}
									</option>
								))}
							</select>
							<select
								disabled={months?.length === 0 ? true : false}
								value={monthValue}
								onChange={handleMonthChange}
							>
								<option value='' disabled>
									Month
								</option>
								{months?.map((month) => (
									<option key={month.value} value={month.value}>
										{month.month}
									</option>
								))}
							</select>
						</>
					) : null}
				</div>
				<button className='btn btn-primary download' onClick={handleDownload}>
					<FaFileDownload className='icon' />
					<span>Download Report</span>
				</button>
				<button
					className='btn btn-secondary csv'
					onClick={() => {
						dispatch({ type: ACTIONS.CSV });
					}}
				>
					<BiData className='icon' />
					<span>Download CSV</span>
				</button>
			</div>
			<div className='page-container'>
				{reportType === REPORT_TYPE[0].value && MAYearValue && MAMonthValue ? (
					<ReportsSummary
						ref={pdfRef}
						data={reportsSummary?.data}
						month={MONTHS.find((month) => MAMonthValue === month.value).month}
						year={MAYearValue}
					/>
				) : reportType === REPORT_TYPE[1].value && deviceID && yearValue && monthValue ? (
					<ReportsPDF
						ref={pdfRef}
						device={device}
						readings={monthlyReport?.data}
						yearValue={yearValue}
						monthName={monthName}
					/>
				) : null}
			</div>
		</div>
	);
};

const MONTHS = [
	{
		value: "01",
		month: "January",
	},
	{
		value: "02",
		month: "February",
	},
	{
		value: "03",
		month: "March",
	},
	{
		value: "04",
		month: "April",
	},
	{
		value: "05",
		month: "May",
	},
	{
		value: "06",
		month: "June",
	},
	{
		value: "07",
		month: "July",
	},
	{
		value: "08",
		month: "August",
	},
	{
		value: "09",
		month: "September",
	},
	{
		value: "10",
		month: "October",
	},
	{
		value: "11",
		month: "November",
	},
	{
		value: "12",
		month: "December",
	},
];

export default Reports;
