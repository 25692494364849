import { useState, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import Autosuggest from "react-autosuggest";
import { BiX } from "react-icons/bi";
import { IoLocationSharp } from "react-icons/io5";

import { ACTIONS } from "../../../contexts/ModalProvider";
import { useSelectedDevice } from "../../../contexts/SelectedDeviceProvider";
import { useCommunityTypes } from "../../../contexts/CommunityTypesProvider";

import useLoc from "../../../hooks/useLoc";
import useAddDevice from "../../../hooks/useAddDevice";
import useEditDevice from "../../../hooks/useEditDevice";
import usePlaces from "../../../hooks/usePlaces";

import Maps from "../../Dashboard/Map/Maps";

const DeviceModal = ({ modal, dispatch }) => {
	const { selectedDevice } = useSelectedDevice();
	const { communityTypes } = useCommunityTypes();

	// Define modal action type
	const ACTION_TYPE = modal.add ? ACTIONS.ADD : modal.edit ? ACTIONS.EDIT : "";

	// Define state variables
	const [invalid, setInvalid] = useState({ name: false, type: false, loc: false });
	const [name, setName] = useState("");
	const [type, setType] = useState("");
	const { lat, lng } = useLoc();
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

	const handleCoordsChange = (position) => {
		setCenter(position);
		setCoordinates(position);
	};

	// Define variables for usePlacesAutocomplete
	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete({ defaultValue: modal.edit ? selectedDevice.location : "" });

	// Fetch Autosuggest Props
	const {
		inputProps,
		renderFormInputComponent,
		getSuggestionValue,
		renderSuggestion,
		onSuggestionsFetchRequested,
		onSuggestionsClearRequested,
		onSuggestionSelected,
	} = usePlaces(
		ready,
		value,
		setValue,
		status,
		data,
		clearSuggestions,
		invalid.loc,
		setCoordinates
	);

	// Set state variables when modal is rendered
	useEffect(() => {
		if (modal.edit) {
			setName(selectedDevice.name);
			setType(selectedDevice.community_type);
			const lat = selectedDevice.latitude;
			const lng = selectedDevice.longitude;
			handleCoordsChange({ lat, lng });
		} else {
			setName("");
			setType("");
			if (lat && lng) handleCoordsChange({ lat, lng });
		}
	}, [selectedDevice, lat, lng, modal]);

	// Define post functions for add and edit device
	const { mutate: addDevice } = useAddDevice();
	const { editDevice } = useEditDevice();

	const handleSubmit = (e) => {
		e.preventDefault();

		// Check if name, type, and location are valid
		if (!name) {
			if (!type) {
				if (!value) {
					return setInvalid({ name: true, type: true, loc: true });
				} else {
					return setInvalid({ name: true, type: true, loc: false });
				}
			} else if (!value) {
				return setInvalid({ name: true, type: false, loc: true });
			} else {
				return setInvalid({ name: true, type: false, loc: false });
			}
		} else if (!type) {
			if (!value) {
				return setInvalid({ name: false, type: true, loc: true });
			} else {
				return setInvalid({ name: false, type: true, loc: false });
			}
		} else if (!value) {
			return setInvalid({ name: false, type: false, loc: true });
		}

		if (modal.edit) {
			const body = {
				id: selectedDevice.id,
				name: name,
				community_type: type,
				location: value,
				latitude: coordinates.lat,
				longitude: coordinates.lng,
			};
			editDevice(selectedDevice.id, body);
		} else if (modal.add) {
			const body = {
				name: name,
				community_type: type,
				location: value,
				latitude: coordinates.lat,
				longitude: coordinates.lng,
			};
			addDevice(body);
		}
		setName("");
		setCoordinates({});
		dispatch({ type: ACTION_TYPE });
	};

	return (
		<div
			className='modal'
			onClick={(e) => e.target === e.currentTarget && dispatch({ type: ACTION_TYPE })}
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<span>{`${ACTION_TYPE} Device`}</span>
					<BiX className='x-icon' onClick={() => dispatch({ type: ACTION_TYPE })} />
				</div>
				<form className='modal-body' onSubmit={handleSubmit}>
					<label className='form-label' htmlFor='device-name'>
						Name
					</label>
					<input
						className={!invalid.name ? "form-input" : "form-input invalid"}
						type='text'
						id='device-name'
						placeholder='Enter Device Name'
						value={name}
						autoComplete='off'
						autoFocus={true}
						onChange={(e) => setName(e.target.value)}
					/>
					<label className='form-label' htmlFor='community-type'>
						Community Type
					</label>
					<select
						className={!invalid.type ? "form-input" : "form-input invalid"}
						id='community-type'
						value={type}
						onChange={(e) => setType(e.target.value)}
					>
						<option value=''>Select Community Type</option>
						{communityTypes.map((type, idx) => (
							<option key={idx} value={idx + 1}>
								{type}
							</option>
						))}
					</select>
					<label className='form-label' htmlFor='device-loc'>
						Location
					</label>
					<div className='form'>
						<Autosuggest
							inputProps={inputProps(false)}
							renderInputComponent={renderFormInputComponent}
							suggestions={data}
							getSuggestionValue={getSuggestionValue}
							onSuggestionsFetchRequested={onSuggestionsFetchRequested}
							onSuggestionsClearRequested={onSuggestionsClearRequested}
							onSuggestionSelected={onSuggestionSelected}
							renderSuggestion={renderSuggestion}
						/>
					</div>
					<div className='minimap-container'>
						<Maps coordinates={center} />
						<IoLocationSharp className='minimap icon' />
					</div>
					<div className='modal-footer'>
						<button
							className='btn btn-cancel'
							onClick={() => dispatch({ type: ACTION_TYPE })}
						>
							Cancel
						</button>
						<button className='btn btn-primary' type='submit'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DeviceModal;
