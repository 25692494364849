import React, { useState, useContext, useRef } from "react";

const MapRefContext = React.createContext();

export function useMapRef() {
    return useContext(MapRefContext);
}

export function MapRefProvider({ children }) {
    const mapRef = useRef();

    return <MapRefContext.Provider value={mapRef}>{children}</MapRefContext.Provider>;
}
