import "./Dashboard.css";

import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { BiMenuAltLeft } from "react-icons/bi";

import { CommunityTypesProvider } from "../../contexts/CommunityTypesProvider";
import { DateRangeProvider } from "../../contexts/DateRangeProvider";
import { DateProvider } from "../../contexts/DateProvider";

import useLoc from "../../hooks/useLoc";

import Header from "./Header/Header";
import DeviceList from "./DeviceList/DeviceList";
import DashContent from "./DashContent/DashContent";
import PlacesAutocomplete from "./PlacesAutocomplete/PlacesAutocomplete";
import Maps from "./Map/Maps";
import AQI from "./AQI/AQI";

const Dashboard = () => {
	const { devices, device_id } = useOutletContext();

	const [open, setOpen] = useState(true);

	const { lat, lng } = useLoc();
	const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

	useEffect(() => {
		if (lat && lng) {
			setCoordinates({ lat: lat, lng: lng });
		}
	}, [lat, lng]);

	return (
		<CommunityTypesProvider>
			<DateRangeProvider>
				<DateProvider>
					<main className='dashboard'>
						<div className={open ? "graph-container open" : "graph-container"}>
							<Header open={open} setOpen={setOpen} />
							{!device_id ? (
								<DeviceList devices={devices?.data} />
							) : (
								<DashContent devices={devices?.data} />
							)}
						</div>
						<div className='maps-container'>
							{!open ? (
								<div className='floating toggle icon'>
									<BiMenuAltLeft onClick={() => setOpen(!open)} />
								</div>
							) : null}
							<PlacesAutocomplete />
							<AQI />
							<Maps devices={devices?.data} coordinates={coordinates} />
						</div>
					</main>
				</DateProvider>
			</DateRangeProvider>
		</CommunityTypesProvider>
	);
};

export default Dashboard;
