import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Calendar } from "react-date-range";
import format from "date-fns/format";
import { subDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useDate } from "../../../contexts/DateProvider";
import useEarliestDateQuery from "../../../hooks/useEarliestDateQuery";
import useLatestDateQuery from "../../../hooks/useLatestDateQuery";

const DatePicker = () => {
	const {device_id} = useParams();
	const { data: earliestDate, isError: earliestDateError } = useEarliestDateQuery(device_id);
	const { data: latestDate, isError: latestDateError } = useLatestDateQuery(device_id);
	const { setDate } = useDate();

	// Date state
	const [calendar, setCalendar] = useState("");

	// Toggle datepicker open close
	const [open, setOpen] = useState(false);

	// Get the target element to toggle
	const refOne = useRef(null);

	useEffect(() => {
		// Event listeners
		document.addEventListener("keydown", hideOnEscape, true);
		document.addEventListener("click", hideOnClickOutside, true);
	}, []);

	useEffect(() => {
		if (!earliestDateError && !latestDateError) {
			if (new Date(latestDate?.data?.date) < subDays(new Date(), 1)) {
				setCalendar(format(new Date(latestDate?.data?.date), "MM/dd/yyyy"));
				setDate(format(new Date(latestDate?.data?.date), "yyyy-MM-dd"));
			} else {
				setCalendar(format(subDays(new Date(), 1), "MM/dd/yyyy"));
				setDate(format(subDays(new Date(), 1), "yyyy-MM-dd"));
			}
		} else {
			setCalendar(format(new Date(), "MM/dd/yyyy"));
		}
	}, [earliestDate, latestDate, setDate]);

	// Hide dropdown on ESC press
	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	};

	// Hide on outside click
	const hideOnClickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	};

	const handleSelect = (date) => {
		setCalendar(format(date, "MM/dd/yyyy"));
		setDate(format(date, "yyyy-MM-dd"));
	};

	return (
		<div className='calendarWrap'>
			<input
				value={calendar}
				readOnly
				className='inputBox'
				onClick={() => setOpen((open) => !open)}
			/>

			<div ref={refOne}>
				{open && (
					<Calendar
						date={new Date(calendar)}
						minDate={earliestDate ? new Date(earliestDate?.data?.date) : new Date()}
						maxDate={
							latestDate ? new Date(latestDate?.data?.date) : subDays(new Date(), 1)
						}
						onChange={handleSelect}
						color='rgb(90, 115, 172)'
						className='calendarElement'
					/>
				)}
			</div>
		</div>
	);
};

export default DatePicker;
