import "./Login.css";
import AirEyeLogo from "../../assets/img/AirEye.svg";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BiUser, BiLockAlt } from "react-icons/bi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

import { login } from "../../services/ApiServices";
import useLocalStorage from "../../hooks/useLocalStorage";

const Login = () => {
	const usernameRef = useRef();
	const passwordRef = useRef();

	const { storedValue: token, setValue: setToken } = useLocalStorage("token", null);
	const [invalid, setInvalid] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (token) {
			navigate("/admin");
		}
	});

	const handleLogin = (e) => {
		e.preventDefault();
		setLoading(true);
		login({ username: usernameRef.current.value, password: passwordRef.current.value })
			.then((res) => {
				const { data } = res;
				setToken(data.token);
				setInvalid(false);
				navigate("/admin");
			})
			.catch((err) => {
				setInvalid(true);
				console.log(err.response.data.errors[0]);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className='login-container'>
			<div className='login card'>
				<div className='logo'>
					<img src={AirEyeLogo} alt='AirEye' />
				</div>
				<h1>LOGIN</h1>
				<div className={invalid ? "invalid" : "invalid hidden"}>
					<BsFillExclamationTriangleFill className='icon' />
					Invalid username or password
				</div>
				<form onSubmit={handleLogin}>
					<div className='login-input'>
						<input type='text' placeholder='Username' autoFocus={true} ref={usernameRef} />
						<div className='input-icon'>
							<BiUser className='icon' />
						</div>
					</div>
					<div className='login-input'>
						<input type='password' placeholder='Password' autoComplete='off' ref={passwordRef} />
						<div className='input-icon'>
							<BiLockAlt className='icon ' />
						</div>
					</div>
					<button type='submit' className='btn btn-primary'>
						Login
					</button>
				</form>
			</div>
		</div>
	);
};

export default Login;
