export const ArduinoCode = (ssid, password, id) =>
	`//---------------------------ENTER REQUIRED DETAILS HERE---------------------------
const char ssid[] = "${ssid}";
const char password[] = "${password}";
const int device_id = ${id}; // CHANGE DEVICE_ID 

float MQ4RO = 0;
float MQ7RO = 0;
float MQ135RO =0;


String API_PATH = "http://aireye.localtonet.com/api/add/" + String(device_id);
String tempQuery;



//---------------------------------------------------------------------------------
#include <WiFi.h>
#include <SoftwareSerial.h>
#include <HTTPClient.h>
#include <TroykaMQ.h>
#include <EEPROM.h>

// Define needed values
#define PIN_MQ135  35
#define PIN_MQ7    34
#define PIN_MQ4    39

#define EEPROM_SIZE 64

// struccture to store data from pms 5003
struct pms5003data { 
    uint16_t framelen;
    uint16_t pm10_standard, pm25_standard, pm100_standard;
    uint16_t pm10_env, pm25_env, pm100_env;
    uint16_t particles_03um, particles_05um, particles_10um, particles_25um, particles_50um, particles_100um;
    uint16_t unused;
    uint16_t checksum;
};
    
struct pms5003data data;

// Serial start form pms5003
SoftwareSerial pmsSerial(23, 22);

// Create MQ sensors
MQ135 mq135(PIN_MQ135);
MQ7 mq7(PIN_MQ7);
MQ4 mq4(PIN_MQ4);
int counter = 0;

void setup()
{

    Serial.begin(9600);
    EEPROM.begin(EEPROM_SIZE);
    callibrate();
    
    Serial.println();
    Serial.println();
    Serial.print("Connecting to ");
    Serial.println(ssid);

    WiFi.begin(ssid, password);

    while (WiFi.status() != WL_CONNECTED) {
        delay(500);
        Serial.print(".");
    }

    Serial.println("");
    Serial.println("WiFi connected");
    Serial.println("IP address: ");
    Serial.println(WiFi.localIP());
}

void loop()
{
    // Begin pms serial
    pmsSerial.begin(9600);
    delay(2500);
    //chech pm data
    if (readpmSdata(&pmsSerial)) {
        // check pm data
        if(WiFi.status()== WL_CONNECTED) {   
                WiFiClient client;
                HTTPClient http;

                http.begin(client, API_PATH); 
                http.addHeader("Content-Type", "application/json");
                
                String httpRequestData = "{\\"mq4\\": " + String(mq4.readMethane()) + ",\\"mq7\\": " + String(mq7.readCarbonMonoxide()) + ",\\"mq135\\": " + String(mq135.readCO2()) + ",\\"pm03\\": " + String(data.particles_03um) + ",\\"pm05\\": " + String(data.particles_05um) + ",\\"pm10\\": " + String(data.particles_10um) + ",\\"pm25\\": " + String(data.particles_25um) + ",\\"pm50\\": " + String(data.particles_50um)+ ",\\"pm100\\": " + String(data.particles_100um) + "}";
                Serial.println(httpRequestData);
                int httpResponseCode = http.POST(httpRequestData);
                if(httpResponseCode == 201) {
                    String payload = http.getString();
                    Serial.println(payload);
                } else {
                    Serial.printf("HTTP POST... failed Error Code : %d, error: %s \\n",httpResponseCode,http.errorToString(httpResponseCode).c_str());
            }

        http.end();
        }else{Serial.println("Does not have internet cconnection");}
    }else{Serial.println("Can't connect to PM2.5 SENSOR");}
    counter++;

    // Serial print for debug
    Serial.print("Counter: ");
    Serial.print(counter);
    Serial.print("\\tCO2: ");
    Serial.print(mq135.readCO2());
    Serial.print(" ppm");
    Serial.print("\\tCO: ");
    Serial.print(mq7.readCarbonMonoxide());
    Serial.print(" ppm");
    Serial.print("\\tCH4: ");
    Serial.print(mq4.readMethane());
    Serial.println(" ppm");
    pmsSerial.end();
    delay(2500);
}


// Callibrate
void callibrate(){
    int address = 1;
    Serial.print(EEPROM.read(address));
    // checks if eeprom has been readed to
    if(EEPROM.read(address) ==42){
        address += 1;
        float M135, M7, M4;
        M4 =  EEPROM.readFloat(address);
        address+= sizeof(float);
        M7 =  EEPROM.readFloat(address);
        address+= sizeof(float);
        M135 =  EEPROM.readFloat(address);
        address+= sizeof(float);
        mq135.calibrate(M135);
        mq7.calibrate(M7);
        mq4.calibrate(M4);
        Serial.print(M135);
        Serial.print(M7);
        Serial.print(M4);
    } else{
        // Delay for heat up
        for(int i =0; i < 30; i++){
            Serial.println("Heating up sensor");
            delay(60000);
        }
        mq135.calibrate();
        mq7.calibrate();
        mq4.calibrate();
        EEPROM.write(address,42);
        address+=1;
        EEPROM.writeFloat(address,mq4.getRo());
        address+=sizeof(float);
        EEPROM.commit();
        EEPROM.writeFloat(address,mq7.getRo());
        address+=sizeof(float);
        EEPROM.commit();
        EEPROM.writeFloat(address,mq135.getRo());
        address+=sizeof(float);
        EEPROM.commit();
        Serial.print("EEPROM WRITE SUCESS");
    }
}

// read the data
boolean readpmSdata(Stream *s) {
    if (! s->available()) {
        Serial.println("Stream not available");
        return false;
    }

    if (s->peek() != 0x42) {
        Serial.println("Stream does not contain 42 starting line");
        s->read();
        return false;
    }
    
    // Now read all 32 bytes
    if (s->available() < 32) {
        Serial.println("stream incomplete");
        return false;
    }
    
    uint8_t buffer[32];    
    uint16_t sum = 0;
    s->readBytes(buffer, 32);
    
    // get checksum ready
    for (uint8_t i=0; i<30; i++) {
        sum += buffer[i];
    }
    uint16_t buffer_u16[15];
    for (uint8_t i=0; i<15; i++) {
        buffer_u16[i] = buffer[2 + i*2 + 1];
        buffer_u16[i] += (buffer[2 + i*2] << 8);
    }
    
    // put it into a nice struct 🙂
    memcpy((void *)&data, (void *)buffer_u16, 30);
    
    if (sum != data.checksum) {
        Serial.println("Checksum failure");
        return false;
    }
    // success!
    return true;
}`;
