import format from "date-fns/format";

import { AQI_LEVELS, aqi_category, between } from "./AQILevels";

export const MultipleReadingsTbody = ({ readings, reportsSummary = false }) => {
	return (
		<tbody>
			{readings?.map((dt, idx) => (
				<tr key={idx}>
					{!reportsSummary ? (
						<td className='date'>{format(new Date(dt.date), "MMM dd")}</td>
					) : (
						<td className='date'>{dt.device_name}</td>
					)}
					<td>{dt.mq135 && Number(dt.mq135)?.toFixed(2)}</td>
					<td>{dt.mq7 && Number(dt.mq7)?.toFixed(2)}</td>
					<td>{dt.mq4 && Number(dt.mq4)?.toFixed(2)}</td>
					<td>{dt.pm03 && Number(dt.pm03)?.toFixed(2)}</td>
					<td>{dt.pm05 && Number(dt.pm05)?.toFixed(2)}</td>
					<td>{dt.pm10 && Number(dt.pm10)?.toFixed(2)}</td>
					<td>{dt.pm25 && Number(dt.pm25)?.toFixed(2)}</td>
					<td>{dt.pm50 && Number(dt.pm50)?.toFixed(2)}</td>
					<td>{dt.pm100 && Number(dt.pm100)?.toFixed(2)}</td>
				</tr>
			))}
		</tbody>
	);
};

export const MultipleReadingsWStatusTbody = ({ readings }) => {
	return (
		<tbody>
			{readings?.map((dt, idx) => (
				<tr key={idx}>
					<td className='date'>{format(new Date(dt.date), "MMM dd")}</td>
					<td>{dt.mq135 && Number(dt.mq135)?.toFixed(2)}</td>
					<td>{dt.mq7 && Number(dt.mq7)?.toFixed(2)}</td>
					<td>{dt.mq4 && Number(dt.mq4)?.toFixed(2)}</td>
					<td>{dt.pm03 && Number(dt.pm03)?.toFixed(2)}</td>
					<td>{dt.pm05 && Number(dt.pm05)?.toFixed(2)}</td>
					<td>{dt.pm10 && Number(dt.pm10)?.toFixed(2)}</td>
					<td>{dt.pm25 && Number(dt.pm25)?.toFixed(2)}</td>
					<td>{dt.pm50 && Number(dt.pm50)?.toFixed(2)}</td>
					<td>{dt.pm100 && Number(dt.pm100)?.toFixed(2)}</td>
					<td>{AQI_LEVELS[aqi_category(dt)?.idx]?.category}</td>
				</tr>
			))}
		</tbody>
	);
};

export const ReadingStatusCell = ({ reading }) => {
	return (
		<>
			{reading.sensor === "MQ135" ? (
				<>
					{reading.reading <= 1000 ? <td>Normal</td> : <td>Unhealthy</td>}
					<td>1000</td>
					<td
						className={`reading ${reading.reading <= 1000 ? "text-green" : "text-red"}`}
					>
						{reading.reading && Number(reading.reading?.toFixed(2))}
					</td>
				</>
			) : reading.sensor === "MQ7" ? (
				<>
					{reading.reading <= 9 ? <td>Normal</td> : <td>Unhealthy</td>}
					<td>9</td>
					<td className={`reading ${reading.reading <= 9 ? "text-green" : "text-red"}`}>
						{reading.reading && Number(reading.reading?.toFixed(2))}
					</td>
				</>
			) : reading.sensor === "MQ4" ? (
				<>
					{reading.reading <= 50 ? <td>Normal</td> : <td>Unhealthy</td>}
					<td>50</td>
					<td className={`reading ${reading.reading <= 50 ? "text-green" : "text-red"}`}>
						{reading.reading && Number(reading.reading?.toFixed(2))}
					</td>
				</>
			) : reading.sensor === "PM2.5" ? (
				<>
					{between(reading.reading, 0, 12) ? <td>Normal</td> : <td>Unhealthy</td>}
					<td>0-12</td>
					<td
						className={`reading ${
							between(reading.reading, 0, 12) ? "text-green" : "text-red"
						}`}
					>
						{reading.reading && Number(reading.reading?.toFixed(2))}
					</td>
				</>
			) : reading.sensor === "PM10.0" ? (
				<>
					{between(reading.reading, 0, 54) ? <td>Normal</td> : <td>Unhealthy</td>}
					<td>0-54</td>
					<td
						className={`reading ${
							between(reading.reading, 0, 54) ? "text-green" : "text-red"
						}`}
					>
						{reading.reading && Number(reading.reading?.toFixed(2))}
					</td>
				</>
			) : (
				<>
					<td style={{ width: "155px" }}>-</td>
					<td>-</td>
					<td className='reading'>
						{reading.reading && Number(reading.reading?.toFixed(2))}
					</td>
				</>
			)}
		</>
	);
};

export const READINGS = (data = null) => [
	{
		id: 1,
		sensor: "MQ135",
		gas: "Carbon Dioxide",
		elmnts: "CO",
		sub: "2",
		reading: data?.data.mq135,
	},
	{
		id: 2,
		sensor: "MQ7",
		gas: "Carbon Monoxide",
		elmnts: "CO",
		sub: "",
		reading: data?.data.mq7,
	},
	{
		id: 3,
		sensor: "MQ4",
		gas: "Methane",
		elmnts: "CH",
		sub: "4",
		reading: data?.data.mq4,
	},
	{
		id: 4,
		sensor: "PM0.3",
		gas: "Particulate Matter (0.3 microns)",
		elmnts: "PM",
		sub: "0.3",
		reading: data?.data.pm03,
	},
	{
		id: 5,
		sensor: "PM0.5",
		gas: "Particulate Matter (0.5 microns)",
		elmnts: "PM",
		sub: "0.5",
		reading: data?.data.pm05,
	},
	{
		id: 6,
		sensor: "PM1.0",
		gas: "Particulate Matter  (1.0 microns)",
		elmnts: "PM",
		sub: "1.0",
		reading: data?.data.pm10,
	},
	{
		id: 7,
		sensor: "PM2.5",
		gas: "Particulate Matter (2.5 microns)",
		elmnts: "PM",
		sub: "2.5",
		reading: data?.data.pm25,
	},
	{
		id: 8,
		sensor: "PM5.0",
		gas: "Particulate Matter (5.0 microns)",
		elmnts: "PM",
		sub: "5.0",
		reading: data?.data.pm50,
	},
	{
		id: 9,
		sensor: "PM10.0",
		gas: "Particulate Matter (10.0 microns)",
		elmnts: "PM",
		sub: "10.0",
		reading: data?.data.pm100,
	},
];
