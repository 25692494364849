import { useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { BiX } from "react-icons/bi";

import { ACTIONS } from "../../../contexts/ModalProvider";
import { useSelectedDevice } from "../../../contexts/SelectedDeviceProvider";

import { ArduinoCode } from "../../../helper/ArduinoCode";

const CodeModal = ({ dispatch }) => {
    const { selectedDevice } = useSelectedDevice();

    const [ssid, setSsid] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div className='modal' onClick={(e) => e.target === e.currentTarget && dispatch({ type: ACTIONS.VIEW })}>
            <div className='modal-content'>
                <div className='modal-header'>
                    <span>Arduino Code</span>
                    <BiX className='x-icon' onClick={() => dispatch({ type: ACTIONS.VIEW })} />
                </div>
                <div className='modal-body'>
                    <label htmlFor='SSID'>SSID</label>
                    <input
                        type='text'
                        className='form-input'
                        id='SSID'
                        value={ssid}
                        autoComplete='off'
                        autoFocus={true}
                        onChange={(e) => setSsid(e.target.value)}
                    />
                    <label htmlFor='password'>Password</label>
                    <input
                        type='text'
                        className='form-input'
                        id='password'
                        value={password}
                        autoComplete='off'
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className='code'>
                        <CopyBlock
                            text={ArduinoCode(ssid, password, selectedDevice.id)}
                            language={"cpp"}
                            showLineNumbers={true}
                            theme={dracula}
                            codeBlock
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodeModal;
