import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteDevice } from "../services/ApiServices";

export default function useDeleteDevice() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: "deleteDevice",
        mutationFn: deleteDevice,
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
        },
    });
}
