import format from "date-fns/format";

export const FIGURE_TYPES = {
	LIVE: "live",
	DAY: "day",
	HISTORY: "history",
	PREDICTION: "prediction",
};

const DOUGHNUT = (data) => [
	{
		label: "MQ135",
		data: data?.mq135,
		backgroundColor: "rgb(90, 115, 172)",
	},
	{
		label: "MQ7",
		data: data?.mq7,
		backgroundColor: "rgb(109, 170, 252)",
	},
	{
		label: "MQ4",
		data: data?.mq4,
		backgroundColor: "rgb(111, 219, 254)",
	},
	{
		label: "PM0.3",
		data: data?.pm03,
		backgroundColor: "rgb(193, 237, 204)",
	},
	{
		label: "PM0.5",
		data: data?.pm05,
		backgroundColor: "rgb(204, 255, 203)",
	},
	{
		label: "PM1.0",
		data: data?.pm10,
		backgroundColor: "rgb(44, 7, 53)",
	},
	{
		label: "PM2.5",
		data: data?.pm25,
		backgroundColor: "rgb(170, 141, 214)",
	},
	{
		label: "PM5.0",
		data: data?.pm50,
		backgroundColor: "rgb(245, 204, 232)",
	},
	{
		label: "PM10.0",
		data: data?.pm100,
		backgroundColor: "rgb(236, 157, 237)",
	},
];

const LINE = (data) => [
	{
		label: "MQ4",
		data: data?.map((dt) => dt.mq4),
		borderColor: "rgb(90, 115, 172)",
		colorStart: "rgba(90, 115, 172, 0.75)",
		colorEnd: "rgba(90, 115, 172, 0)",
	},
	{
		label: "MQ7",
		data: data?.map((dt) => dt.mq7),
		borderColor: "rgb(109, 170, 252)",
		colorStart: "rgba(109, 170, 252, 0.75)",
		colorEnd: "rgba(109, 170, 252, 0)",
	},
	{
		label: "MQ135",
		data: data?.map((dt) => dt.mq135),
		borderColor: "rgb(111, 219, 254)",
		colorStart: "rgba(111, 219, 254, 0.75)",
		colorEnd: "rgba(111, 219, 254, 0)",
	},
	{
		label: "PM0.3",
		data: data?.map((dt) => dt.pm03),
		borderColor: "rgb(193, 237, 204)",
		colorStart: "rgba(193, 237, 204, 0.75)",
		colorEnd: "rgba(193, 237, 204, 0)",
	},
	{
		label: "PM0.5",
		data: data?.map((dt) => dt.pm05),
		borderColor: "rgb(204, 255, 203)",
		colorStart: "rgba(204, 255, 203, 0.75)",
		colorEnd: "rgba(204, 255, 203, 0)",
	},
	{
		label: "PM1.0",
		data: data?.map((dt) => dt.pm10),
		borderColor: "rgb(44, 7, 53)",
		colorStart: "rgba(44, 7, 53, 0.75)",
		colorEnd: "rgba(44, 7, 53, 0)",
	},
	{
		label: "PM2.5",
		data: data?.map((dt) => dt.pm25),
		borderColor: "rgb(170, 141, 214)",
		colorStart: "rgba(170, 141, 214, 0.75)",
		colorEnd: "rgba(170, 141, 214, 0)",
	},
	{
		label: "PM5.0",
		data: data?.map((dt) => dt.pm50),
		borderColor: "rgb(245, 204, 232)",
		colorStart: "rgba(245, 204, 232, 0.75)",
		colorEnd: "rgba(245, 204, 232, 0)",
	},
	{
		label: "PM10.0",
		data: data?.map((dt) => dt.pm100),
		borderColor: "rgb(236, 157, 237)",
		colorStart: "rgba(236, 157, 237, 0.75)",
		colorEnd: "rgba(236, 157, 237, 0)",
	},
];

export const DOUGHNUT_DATA = (data) => {
	return {
		labels: DOUGHNUT(data).map((dt) => dt.label),
		datasets: [
			{
				label: "Day Reading",
				data: DOUGHNUT(data).map((dt) => dt.data),
				backgroundColor: DOUGHNUT(data).map((dt) => dt.backgroundColor),
			},
		],
	};
};

export const LINE_DATA = (data) => {
	return {
		labels: data?.map((dt) => format(new Date(dt.date), "MMM dd")),
		datasets: LINE(data).map((line) => ({
			label: line.label,
			data: line.data,
			fill: "start",
			pointHoverRadius: 5,
			tension: 0.4,
			borderColor: line.borderColor,
			backgroundColor: (context) => {
				const ctx = context.chart.ctx;
				const gradient = ctx.createLinearGradient(0, 0, 0, 250);
				gradient.addColorStop(0, line.colorStart);
				gradient.addColorStop(1, line.colorEnd);
				return gradient;
			},
		})),
	};
};

export const DOUGHNUT_OPTIONS = {
	plugins: {
		legend: {
			position: "right",
			labels: {
				boxWidth: 20,
				boxHeight: 20,
			},
		},
	},
};

export const LINE_OPTIONS = {
	plugins: {
		legend: {
			labels: {
				boxWidth: 10,
				boxHeight: 10,
			},
		},
	},
	scales: {
		x: {
			ticks: {
				display: true,
			},
		},
		y: {
			min: 0,
		},
	},
};
