import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDayReading } from "../services/ApiServices";
import { useDate } from "../contexts/DateProvider";

export default function useDayDataQuery() {
    const { device_id } = useParams();
    const { date } = useDate();

    const fetchDayData = () => {
        return getDayReading(device_id, date);
    };

    return useQuery({
        queryKey: ["dayData", device_id, date],
        queryFn: fetchDayData,
        enabled: !!device_id && !!date,
    });
}
