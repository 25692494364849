import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCommunityTypes } from "../services/ApiServices";
import { useEffect } from "react";

const CommunityTypesContext = React.createContext();

export function useCommunityTypes() {
	return useContext(CommunityTypesContext);
}

export function CommunityTypesProvider({ children }) {
	const [communityTypes, setCommunityTypes] = useState([]);

	const { data, isLoading } = useQuery({
		queryKey: ["communityTypes"],
		queryFn: getCommunityTypes,
	});

	useEffect(() => {
		if (!isLoading) setCommunityTypes(data?.data?.map((communityType) => communityType.name));
	}, [data, isLoading]);

	return (
		<CommunityTypesContext.Provider value={{ communityTypes }}>
			{children}
		</CommunityTypesContext.Provider>
	);
}
