import React, { useContext, useState } from "react";

const SelectedDeviceContext = React.createContext();

export function useSelectedDevice() {
    return useContext(SelectedDeviceContext);
}

export function SelectedDeviceProvider({ children }) {
    const [selectedDevice, setSelectedDevice] = useState({});

    return (
        <SelectedDeviceContext.Provider value={{ selectedDevice, setSelectedDevice }}>
            {children}
        </SelectedDeviceContext.Provider>
    );
}
