import AirEye from "../../../assets/img/AirEyeLogo.png";
import React from "react";

import { READINGS, MultipleReadingsTbody } from "../../../helper/Readings";

const ReportsSummary = React.forwardRef(({ data, month, year }, ref) => {
	return (
		<div ref={ref} className='page'>
			<div className='header'>
				<div className='logo'>
					<img src={AirEye} alt='AirEye' />
				</div>
				<h1 className='title'>AirEye</h1>
			</div>
			<p className='report-details'>Average Gas Readings for{` ${month} ${year}`}</p>
			<div className='content'>
				<table className='reading-table multiple'>
					<thead>
						<tr>
							<th></th>
							{READINGS().map((gas) => (
								<th key={gas.id}>
									{gas.elmnts}
									<sub>{gas.sub}</sub>
								</th>
							))}
						</tr>
					</thead>
					<MultipleReadingsTbody readings={data} reportsSummary={true} />
				</table>
			</div>
		</div>
	);
});

export default ReportsSummary;
