import AirEyeDefault from "../../../assets/img/AirEyePin-Default.svg";
import AirEyeSelected from "../../../assets/img/AirEyePin-Selected.svg";
import AirEyeRed from "../../../assets/img/AirEyePin-Red.svg";

import { useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { GoogleMap, Marker, MarkerClusterer, Circle } from "@react-google-maps/api";

import { useMapRef } from "../../../contexts/MapRefProvider";

import { MapStyles } from "../../../helper/MapStyles";

const Maps = ({ devices, coordinates }) => {
	const mapRef = useMapRef();

	const center = useMemo(() => coordinates, [coordinates]);
	const onLoad = useCallback((map) => (mapRef.current = map), []);
	const options = useMemo(
		() => ({ styles: MapStyles, disableDefaultUI: true, clickableIcons: false }),
		[]
	);

	const { device_id } = useParams();
	const navigate = useNavigate();

	const handleClick = (id) => {
		if (id === Number(device_id)) {
			navigate(`/`);
		} else {
			navigate(`/${id}`);
		}
	};

	return (
		<>
			<GoogleMap
				zoom={15}
				center={center}
				mapContainerClassName='google-maps'
				options={options}
				onLoad={onLoad}
			>
				{devices?.map((device) => (
					<Marker
						key={device.id}
						position={{ lat: device.latitude, lng: device.longitude }}
						icon={{
							url: device.id === Number(device_id) ? AirEyeSelected : AirEyeDefault,
						}}
						onClick={() => handleClick(device.id)}
					/>
				))}
			</GoogleMap>
		</>
	);
};

export default Maps;
