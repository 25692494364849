import { useState, useEffect } from "react";

export default function useLoc() {
    const [position, setPosition] = useState({ lat: 13.94389820098877, lng: 121.17611694335938 });
    const [error, setError] = useState(null);

    const onChange = ({ coords }) => {
        setPosition({
            lat: coords.latitude,
            lng: coords.longitude,
        });
    };

    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        const geo = navigator.geolocation;
        if (!geo) {
            setError("Geolocation is not supported");
            return;
        }

        const watcher = geo.watchPosition(onChange, onError);
        return () => geo.clearWatch(watcher);
    }, []);

    return { ...position, error };
}
