import { getGeocode, getLatLng } from "use-places-autocomplete";

import { BiSearch } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";

import { useMapRef } from "../contexts/MapRefProvider";

export default function usePlaces(
    ready,
    value,
    setValue,
    status,
    data,
    clearSuggestions,
    invalid = false,
    setCoordinates = null
) {
    const mapRef = useMapRef();

    const inputProps = (search) => ({
        value,
        placeholder: search ? "Search..." : "Enter Device Location",
        disabled: !ready,
        onChange: (e) => e.key !== "Enter" && setValue(e.target.value),
    });

    const renderSearchInputComponent = (inputProps) => (
        <div className='search-input'>
            <input {...inputProps} />
            <BiSearch className='icon' />
        </div>
    );

    const renderFormInputComponent = (inputProps) => (
        <input {...inputProps} className={!invalid ? "form-input" : "form-input invalid"} />
    );

    const getSuggestionValue = (suggestion) => suggestion.description;

    const renderSuggestion = (suggestion, { isHighlighted }) => (
        <div className={!isHighlighted ? "suggestions" : "highlighted suggestions"}>
            <div className='icon'>
                <FaMapMarkerAlt />
            </div>
            <div>{suggestion.description}</div>
        </div>
    );

    const onSuggestionsFetchRequested = ({ value, reason }) => {
        const inputValue = value.trim();
        if (inputValue.length === 0) return;
        if (status === "OK") return data;
    };

    const onSuggestionsClearRequested = () => {
        clearSuggestions();
    };

    const onSuggestionSelected = async (e, { suggestionValue, method }) => {
        if (method === "enter") {
            e.preventDefault();
        }

        setValue(suggestionValue, false);
        clearSuggestions();

        const results = await getGeocode({ address: suggestionValue });
        const { lat, lng } = await getLatLng(results[0]);
        mapRef.current?.panTo({ lat, lng });
        setCoordinates && setCoordinates({ lat, lng });
    };

    return {
        inputProps,
        renderSearchInputComponent,
        renderFormInputComponent,
        getSuggestionValue,
        renderSuggestion,
        onSuggestionsFetchRequested,
        onSuggestionsClearRequested,
        onSuggestionSelected,
    };
}
