import "./Modal.css";

import { useModal } from "../../../contexts/ModalProvider";

import DeviceModal from "./DeviceModal";
import PromptModal from "./PromptModal";
import CodeModal from "./CodeModal";
import CSVModal from "./CSVModal";

const Modal = () => {
	const { modal, dispatch } = useModal();

	return (
		<>
			{modal.add || modal.edit ? (
				<DeviceModal modal={modal} dispatch={dispatch} />
			) : modal.reset || modal.delete ? (
				<PromptModal modal={modal} dispatch={dispatch} />
			) : modal.view ? (
				<CodeModal dispatch={dispatch} />
			) : modal.csv ? (
				<CSVModal dispatch={dispatch} />
			) : null}
		</>
	);
};

export default Modal;
