import "./DeviceList.css";

import { useState, useMemo, useCallback } from "react";

import ReadingControls from "./ReadingControls";
import DeviceItems from "./DeviceItems";
import { READINGS } from "../../../helper/Readings";
import { useCommunityTypes } from "../../../contexts/CommunityTypesProvider";

const SENSORS = READINGS().map((reading) => reading.sensor);

const DeviceList = ({ devices }) => {
	const { communityTypes } = useCommunityTypes();
	const [search, setSearch] = useState("");
	const [sensorFilters, setSensorFilters] = useState({
		sensors: SENSORS,
		filters: new Set(),
	});
	const [comTypeFilters, setComTypeFilters] = useState({
		comTypes: communityTypes,
		filters: new Set(),
	});

	const onSensorFilterChange = useCallback((event) => {
		setSensorFilters(
			(prev) => {
				let filters = new Set(prev.filters);
				let sensors = SENSORS;

				if (event.target.checked) {
					filters.add(event.target.value);
				} else {
					filters.delete(event.target.value);
				}

				if (filters.size) {
					sensors = sensors.filter((sensor) => {
						return filters.has(sensor);
					});
				}

				return {
					filters,
					sensors,
				};
			},
			[setSensorFilters]
		);
	});

	const onComTypeFilterChange = useCallback((event) => {
		setComTypeFilters(
			(prev) => {
				let filters = new Set(prev.filters);
				let comTypes = communityTypes;

				if (event.target.checked) {
					filters.add(event.target.value);
				} else {
					filters.delete(event.target.value);
				}

				if (filters.size) {
					comTypes = comTypes.filter((comTypes) => {
						return filters.has(comTypes);
					});
				}

				return {
					filters,
					comTypes,
				};
			},
			[setComTypeFilters]
		);
	});

	const filteredDevices = useMemo(() => {
		const typesIdx = comTypeFilters.comTypes.map(
			(comType) => communityTypes.indexOf(comType) + 1
		);

		if (typesIdx.length === 0) {
			return devices;
		} else {
			return devices?.filter((device) => typesIdx.includes(device.community_type));
		}
	}, [comTypeFilters, communityTypes, devices]);

	return (
		<div className='devices-container'>
			<ReadingControls
				search={search}
				setSearch={setSearch}
				communityTypes={communityTypes}
				onComTypeFilterChange={onComTypeFilterChange}
				onSensorFilterChange={onSensorFilterChange}
			/>
			<div className='content'>
				{filteredDevices
					?.filter((val) => {
						if (search === "") {
							return val;
						} else if (
							val.name.toLowerCase().includes(search.toLowerCase()) ||
							val.location.toLowerCase().includes(search.toLowerCase())
						) {
							return val;
						}
					})
					?.map((device) => (
						<DeviceItems
							key={device.id}
							device={device}
							communityTypes={communityTypes}
							sensors={sensorFilters.sensors}
						/>
					))}
			</div>
		</div>
	);
};

export default DeviceList;
