import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { BiChevronUp, BiChevronDown } from "react-icons/bi";

import { useMapRef } from "../../../contexts/MapRefProvider";
import useLatestLiveDateQuery from "../../../hooks/useLatestLiveDateQuery";
import useLiveDataQuery from "../../../hooks/useLiveDataQuery";
import { READINGS, ReadingStatusCell } from "../../../helper/Readings";

const DeviceItems = ({ device, communityTypes, sensors }) => {
	const mapRef = useMapRef();
	const { data: latestLiveDate } = useLatestLiveDateQuery(device?.id);
	const navigate = useNavigate();

	const status = useMemo(
		() =>
			Math.abs(new Date() - new Date(latestLiveDate?.data?.date)) / 60000 <= 1
				? "Online"
				: "Offline",
		[latestLiveDate]
	);

	const [checked, setChecked] = useState(false);

	return (
		<div className='device'>
			<div
				className='info'
				onClick={() => {
					mapRef.current?.panTo({ lat: device?.latitude, lng: device?.longitude });
					navigate(`/${device?.id}/live`);
				}}
			>
				<div className='device-name'>{device?.name}</div>
				<div className='device-loc'>{device?.location}</div>
				<div className='comm-type'>{communityTypes[device?.community_type - 1]}</div>
				<div className={status ? `status ${status}` : "status"}>
					<div></div>
					{status ? status : "Loading"}
				</div>
			</div>
			<label htmlFor={`view-readings-${device?.id}`} className='view-readings'>
				<span>{`${!checked ? `View` : `Hide`} Readings`}</span>
				<div className='line'></div>
				{!checked ? (
					<div className='icon'>
						<BiChevronDown />
					</div>
				) : (
					<div className='icon'>
						<BiChevronUp />
					</div>
				)}
			</label>
			<input
				type='checkbox'
				id={`view-readings-${device?.id}`}
				className='view-readings-checkbox'
				checked={checked}
				onChange={() => {
					setChecked(!checked);
				}}
			/>
			<div className='readings'>
				{checked ? (
					<ItemLiveReading id={device?.id} sensors={sensors} status={status} />
				) : null}
			</div>
		</div>
	);
};

const ItemLiveReading = ({ id, sensors, status }) => {
	const { data } = useLiveDataQuery(String(id));

	const filteredReadings = useMemo(
		() => READINGS(data).filter((reading) => sensors.includes(reading.sensor)),
		[sensors, data]
	);

	return (
		<table className={status === "Offline" ? "Offline" : ""}>
			<tbody>
				{filteredReadings.map((reading) => (
					<tr key={reading.id}>
						<td>
							<span>{reading.elmnts}</span>
							<sub>{reading.sub}</sub>
						</td>
						<ReadingStatusCell reading={reading} />
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default DeviceItems;
