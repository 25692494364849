import { useState } from "react";
import { BiSearch, BiPlus } from "react-icons/bi";
import { ACTIONS, useModal } from "../../../contexts/ModalProvider";

import Device from "./Device";

const Devices = ({ devices }) => {
	const [search, setSearch] = useState("");
	const { dispatch } = useModal();

	const handleAdd = () => {
		dispatch({ type: ACTIONS.ADD });
	};

	return (
		<div className='content'>
			<div className='content-name'>
				<h1 className='title'>Devices</h1>
				<div className='device-search'>
					<input
						type='text'
						name='search'
						placeholder='Search...'
						autoComplete='off'
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<BiSearch className='icon' />
				</div>
				<button className='btn btn-primary no-text' onClick={handleAdd}>
					<BiPlus className='icon' />
				</button>
			</div>
			<div className='card'>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Location</th>
							<th>Community Type</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{devices?.data
							?.filter((val) => {
								if (search === "") {
									return val;
								} else if (
									val.name.toLowerCase().includes(search.toLowerCase()) ||
									val.location.toLowerCase().includes(search.toLowerCase())
								) {
									return val;
								}
							})
							?.map((device) => (
								<Device key={device.id} device={device} />
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Devices;
