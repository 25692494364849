import "./Figures.css";

import { useState } from "react";
import { useParams } from "react-router-dom";

import { FIGURE_TYPES } from "../../../helper/GraphSettings";

import DatePicker from ".././Calendars/DatePicker";
import DateRangeHistory from ".././Calendars/DateRangeHistory";
import DateRangePrediction from ".././Calendars/DateRangePrediction";
import Toggle from "../Toggle/Toggle";
import LiveReadings from "../ReadingLive/LiveReadings";
import Day from "../ReadingDay/Day";
import Historical from "../ReadingHistorical/Historical";
import Prediction from "../ReadingPrediction/Prediction";

const FORMAT = {
	TABLE: "table",
	GRAPH: "graph",
};

const Figures = () => {
	const { figure } = useParams();
	const [selectedFormat, setSelectedFormat] = useState(FORMAT.TABLE);

	return (
		<>
			{figure !== FIGURE_TYPES.LIVE ? (
				<div className='figure-configs'>
					<Toggle
						format={FORMAT}
						selectedFormat={selectedFormat}
						setSelectedFormat={setSelectedFormat}
					/>
					{figure === FIGURE_TYPES.DAY ? (
						<DatePicker />
					) : figure === FIGURE_TYPES.HISTORY ? (
						<DateRangeHistory />
					) : figure === FIGURE_TYPES.PREDICTION ? (
						<DateRangePrediction />
					) : null}
				</div>
			) : null}

			{figure === FIGURE_TYPES.LIVE ? (
				<LiveReadings />
			) : figure === FIGURE_TYPES.DAY ? (
				<Day FORMAT={FORMAT} selectedFormat={selectedFormat} />
			) : figure === FIGURE_TYPES.HISTORY ? (
				<Historical FORMAT={FORMAT} selectedFormat={selectedFormat} />
			) : figure === FIGURE_TYPES.PREDICTION ? (
				<Prediction FORMAT={FORMAT} selectedFormat={selectedFormat} />
			) : null}
		</>
	);
};

export default Figures;
