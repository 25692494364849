import { useQuery } from "@tanstack/react-query";
import { getEarliestDate } from "../services/ApiServices";

export default function useEarliestDateQuery(id) {
	const fetchEarliestDate = () => {
		return getEarliestDate(id);
	};

	return useQuery({
		queryKey: ["earliestDate", String(id)],
		queryFn: fetchEarliestDate,
		enabled: !!id,
	});
}
