import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createDevice } from "../services/ApiServices";

export default function useAddDevice() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: "addDevice",
        mutationFn: createDevice,
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
        },
    });
}
