export const AQI_LEVELS = [
	{
		category: "Good",
		class: "good",
		description:
			"Air quality is considered satisfactory, and air pollution poses little or no risk.",
	},
	{
		category: "Fair",
		class: "fair",
		description:
			"Air quality is considered satisfactory, and air pollution poses little or no risk.",
	},
	{
		category: "Unhealthy (SG)",
		class: "unhealthy-sg",
		description:
			"People with respiratory disease such as asthma, should limit outdoor exertion.",
	},
	{
		category: "Very Unhealthy",
		class: "very-unhealthy",
		description:
			"Pedestrians should avoid heavy traffic areas. People with heart or respiratory disease, such as asthma should stay indoors and rest as much as possible. Unnecessary trips should be postponed. People should voluntarily restrict the use of vehicles.",
	},
	{
		category: "Acutely Unhealthy",
		class: "acutely-unhealthy",
		description:
			"People should limit outdoor exertion. People with heart or respiratory disease, such as asthma, should stay indoors and rest as much as possible. Unnecessary trips should be postponed. Motor vehicle use may be restricted. Industrial activities may be curtailed.",
	},
	{
		category: "Emergency",
		class: "emergency",
		description:
			"Everyone should remain indoors, (keeping windows and doors closed unless heat stress is possible). Motor vehicle use should be prohibited except for emergency situations. Industrial activities, except that which is vital for public safety and health, should be curtailed.",
	},
];

const AQI_BP = {
	GOOD: {
		min: 0,
		max: 25.0,
	},
	FAIR: {
		min: 25.1,
		max: 35.0,
	},
	UNHEALTHY_SG: {
		min: 35.1,
		max: 45.0,
	},
	VERY_UNHEALTHY: {
		min: 45.1,
		max: 55.0,
	},
	ACUTELY_UNHEALTHY: {
		min: 55.1,
		max: 90.0,
	},
	EMERGENCY: {
		min: 90.1,
		max: 999.0,
	},
};

export function aqi_category(aqi) {
	if (aqi) {
		if (between(aqi.pm25, AQI_BP.GOOD.min, AQI_BP.GOOD.max)) {
			return { idx: 0, date: aqi.date };
		} else if (between(aqi.pm25, AQI_BP.FAIR.min, AQI_BP.FAIR.max)) {
			return { idx: 1, date: aqi.date };
		} else if (between(aqi.pm25, AQI_BP.UNHEALTHY_SG.min, AQI_BP.UNHEALTHY_SG.max)) {
			return { idx: 2, date: aqi.date };
		} else if (between(aqi.pm25, AQI_BP.VERY_UNHEALTHY.min, AQI_BP.VERY_UNHEALTHY.max)) {
			return { idx: 3, date: aqi.date };
		} else if (between(aqi.pm25, AQI_BP.ACUTELY_UNHEALTHY.min, AQI_BP.ACUTELY_UNHEALTHY.max)) {
			return { idx: 4, date: aqi.date };
		} else {
			return { idx: 5, date: aqi.date };
		}
	}
}

export function between(val, min, max) {
	return val >= min && val <= max;
}
