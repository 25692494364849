import { useParams } from "react-router-dom";

import { useStatus } from "../../../contexts/StatusProvider";
import useLiveDataQuery from "../../../hooks/useLiveDataQuery";
import { READINGS, ReadingStatusCell } from "../../../helper/Readings";

const LiveReadings = () => {
	const { device_id } = useParams();
	const { data } = useLiveDataQuery(device_id);
	const { status } = useStatus();

	return (
		<table className={`reading-table${status === "Offline" ? " Offline" : ""}`}>
			<thead>
				<tr>
					<th>Gas</th>
					<th>Status</th>
					<th>Standard</th>
					<th>Reading</th>
				</tr>
			</thead>
			<tbody>
				{READINGS(data).map((reading) => (
					<tr key={reading.id}>
						<td>{reading.gas}</td>
						<ReadingStatusCell reading={reading} />
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default LiveReadings;
